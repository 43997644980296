a {
    text-decoration: none;
}

.about-boxes {
    position: relative;
    padding: 0 3.5vw;
}

.home-card {
    margin-top: 4vw !important;
}

#card-image {
    height: 35vh;
    object-fit: cover;
    width: 100%;
    border-radius: 15px;
    padding: 8px 8px 0 8px !important;
}

.about-boxes .container-fluid {
    position: relative;
    background-color: rgba(0, 6, 18, 0.9);
}

.about-boxes .card {
    width: 100%;
    border-radius: 3px;
    border: 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    margin-top: 2vw;
}

.about-boxes .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.about-boxes .card-title {
    font-weight: 400;
    text-align: center;
    margin: 0;
    padding: 0;
}

.about-boxes .card-title p {
    text-align: center;
    margin: 0;
    padding: 25px 0;
    font-size: 1.4vw;
}

/* For mobile devices */
@media (max-width: 768px) {
    .about-boxes {
        padding: 0 2vw; /* Reduced padding for mobile */
    }

    .home-card {
        margin-top: 20px !important;
        padding: 0 2vw; /* Reduced padding for mobile */
    }
/* 
    .card-box {
        margin-bottom: 15px; 
    } */

    #card-image {
        height: 30vh !important; 
        transition: transform 0.3s ease-in-out;
    }

    .about-boxes .card-title p {
        font-size: 3.5vw;
        padding: 20px 0;
    }
}

/* For tablets */
@media (min-width: 768px) and (max-width: 1024px) {
    .about-boxes {
        padding: 0 4vw; /* Consistent padding on tablets */
    }

    /* .card-box {
        margin-bottom: 15px; 
    } */

    #card-image {
        height: 25vh; /* Adjust card image height for tablets */
    }

    .about-boxes .card-title p {
        font-size: 1.6vw;
    }
}

/* For large desktop and 4K displays */
@media (min-width: 1440px) {
    #card-image {
        height: 43vh; /* Larger image for big screens */
    }
}

@media (min-width: 1024px) {
    /* .card {
        margin-bottom: 1.8vw
    } */
}

/* Increase spacing for cards on desktop (1024px and above) */
@media (min-width: 2560px) {
    .card-box {
        padding-left: 30px;
        padding-right: 30px;
    }

    .about-boxes .card-title p {
        font-size: 2.5rem;
        padding: 30px 0;
    }

    /* .card {
        margin-bottom: 1.8vw
    } */
}