.lab-about-boxes {
    position: relative;
    margin-top: 3vw;
    margin-left: 2.8vw;
    margin-right: 2.8vw;
    /* border: 1px solid blueviolet; */
}


.section-card{
   
}

.lab-about-boxes .container,
.lab-about-boxes .container-fluid {
    position: relative;
    z-index: 10;
    background-color: rgba(0, 6, 18, 0.9);

}

#lab-card-image {
    height: 250px;
    object-fit: cover;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    border: 2px solid #00CCFF;
}

.lab-about-boxes .lab-card {
   
    border: 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.lab-about-boxes .lab-card-icon i {
    font-size: 32px;
    color: #fff;
    width: 64px;
    height: 64px;
    padding-top: 5px;
    text-align: center;
    background-color: #ff4a17;
    border-radius: 4px;
    border: 4px solid #fff;
    transition: 0.3s;
    display: inline-block;
}

.lab-about-boxes .lab-card-body {
    border: 2px solid #00CCFF;
    border-radius: 10px;
    min-height: 10vw; /* Adjusts height based on viewport width */
    padding: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.lab-section-heading{
    border: 2px solid #00CCFF;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 4vw;
    margin-right: 4vw;
    color: white;
    font-size: 2.8vw;
    font-family: 'Roboto Slab';
    text-align: center;
    margin-top: 6vw;;
}

.lab-about-boxes .lab-card-title {
    font-weight: 800;
    font-family: 'Roboto Slab';
    text-align: center;
    font-size: 2vw; 
}


.lab-about-boxes .lab-card-title a {
    color: #15222b;
    
}

.lab-about-boxes .lab-card-title a:hover {
    color: #ff4a17;
}

.lab-about-boxes .lab-card-text {
    color: #5e5e5e;
}

.lab-about-boxes .lab-card:hover .lab-card-icon i {
    background: #fff;
    color: #ff4a17;
}

.lab-card-heading {
    color: white;
    font-size: 2.8vw;
    font-family: 'Roboto Slab';
    /* margin-left: 4vw !important;
    margin-right: 4vw !important; */
    text-align: center;
    /* width: 100%; */
    margin-top: 6vw;;
}

.automation-heading {
    color: white;
    font-size: 2.8vw;
    font-family: 'Roboto Slab';
    /* margin-left: 4vw !important;
    margin-right: 4vw !important; */
    text-align: center;
    /* width: 100%; */
    margin-top: 6vw;;
}

@media (max-width: 1024px) {
    .lab-about-boxes {
        background-attachment: scroll;
    }

    #lab-card-image {
        height: 250px;
        object-fit: cover;
        width: 100%;
        
        border: 2px solid #00CCFF;
    }

    .lab-about-boxes .lab-card-title {
        font-size: 3vw; /* Increase font size for better readability */
    }

    .lab-about-boxes .lab-card-body {
        min-height: 15vw; /* Adjust min-height for smaller screens */
        padding: 2vw; /* Increase padding slightly */
    }
}

.lab-link {
    text-decoration: none;
}

@media only screen and (max-width: 768px) {

    .lab-about-boxes {
        position: relative;
        margin-top: 3vw;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    #lab-card-image {
        height: 250px;
        margin-bottom: 25px;
    }

    .lab-about-boxes {
        background-attachment: scroll;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .lab-about-boxes .lab-card-body {
        min-height: 12vw; /* Larger min-height for mobile */
        padding: 1vw; /* Further increase padding for mobile */
    }

    .lab-card-box {
        width: 100%;
    }

    .lab-card {
        width: 100%;
    }

    .lab-card-heading {
        color: white;
        font-size: 4.8vw;
        font-family: 'Roboto Slab';
        text-align: center;
        margin-top: 8px !important;
    }

    .automation-heading {
        color: white;
        font-size: 4.8vw;
        font-family: 'Roboto Slab';
        text-align: center;
        margin-top: 30px !important;
    }

    .lab-about-boxes .lab-card-title {
        font-size: 4vw; 
    }

    .lab-section-heading{
        padding: 10px;
        margin-top: 30px !important;
        font-size: 4.8vw;
        font-family: 'Roboto Slab';
        text-align: center;
    }
}

/* Large desktop screens */
@media (min-width: 1440px) {
    .lab-about-boxes .lab-card-title {
        font-size: 1.5vw; /* Decrease font size slightly for large screens */
    }

    .lab-about-boxes .lab-card-body {
        min-height: 8vw; /* Smaller min-height for larger screens */
        padding: 1vw; /* Decrease padding slightly */
    }
}

@media (min-width: 1860px) and (max-width: 2550px) {
    #lab-card-image {
        height: 500px;
    }
}

@media only screen and (min-width: 2560px) {

    #lab-card-image {
        height: 20vw;
    }

    .lab-section-heading{
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 68px;
    }

    .lab-about-boxes {
        position: relative;
        margin-top: 3vw;
        margin-left: 3.5vw;
        margin-right: 3.5vw;
    }

    .lab-about-boxes .lab-card-title {
        font-size: 1.2vw; /* Further decrease font size for ultra-wide screens */
    }

    .lab-about-boxes .lab-card-body {
        min-height: 6vw; 
        padding: 0.8vw; 
    }

    .lab-card-heading {
        font-size: 68px;
    }

    .automation-heading {
        font-size: 68px;
    }
}