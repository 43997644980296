@media (min-width: 2560px) {
    .modal-dialog {
        max-width: 900px; 
    }

    .modal-title {
        font-size: 32px; 
        font-weight: 700;
    }

    /* Modal Button Styling */
.btn_Design {
    background-color: #3b3b3b !important;  /* Dark gray background */
    border-color: #29c5ea !important;     /* Border color */
    color: white !important;               /* Text color */
    font-weight: 100;
    padding: 15px 40px;                   /* Button padding */
    font-size: 18px;
}

.Button_align {
    justify-content: center;
}

.btn_Design:hover {
    background-color: #29c5ea !important; /* Hover background color */
    border-color: #29c5ea !important;     /* Hover border color */
    color: white !important;               /* Ensure text stays white */
}

.btn-primary {
    background-color: #3b3b3b !important;  /* Set background color */
    border-color: #29c5ea !important;     /* Set border color */
    color: white !important;               /* Text color */
}

    .modal-header {
        padding: 25px; 
    }

    .modal-body {
        padding: 30px; 
        font-size: 18px; 
    }

    .form-control {
        height: 60px; 
        font-size: 18px; 
        padding: 10px 15px; 
    }

    .form-label {
        font-size: 15px; 
        margin-bottom: 10px;
        color: #00061c;
    }

    
    .form-control#message {
        height: 150px; 
    }

    .new-modal-footer {
        display: flex;
        justify-content: center;  /* Center the button horizontally */
        align-items: center;      /* Center the button vertically */
        width: 100%;
        padding: 20px 30px;
    } 

    .btn {
        font-size: 18px; 
        padding: 15px 40px; 
    }

    
    .btn-primary {
        background-color: #007bff;
        border-color: #007bff;
    }

    .btn_design {
        background-color: #3b3b3b !important;
        border-color: #29c5ea !important;
        color: white; /* Add text color if needed */
    }
    
    .btn_design .btn-primary {
        background-color: #3b3b3b !important;
        border-color: #29c5ea !important;
        color: white !important; /* Ensure text color is white */
    }

    
    /* .btn-secondary {
        background-color: #6c757d;
        border-color: #6c757d;
    } */

    
    .row .col {
        margin-bottom: 20px ;
    }
}
.new-modal-footer {
    display: flex;
    justify-content: center;  /* Center the button horizontally */
    align-items: center;      /* Center the button vertically */
    width: 100;
    padding: 20px 30px;
}

.modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100;
    padding: 20px 30px;
}

.btn_Design {
    background-color: #3b3b3b !important;  /* Dark gray background */
    border-color:2px solid #29c5ea !important;     /* Border color */
    color: white !important;               /* Text color */
    border-radius: 10px;
    font-weight: 100;
    padding: 5px 15px;                   /* Button padding */
    font-size: 18px;
    transition: background-color 0.3s, border-color 0.3s;
}

.btn_Design:hover {
    background-color: #29c5ea !important; /* Hover background color */
    border-color: 2px solid #29c5ea !important;     /* Hover border color */
    color: white !important;               /* Ensure text stays white */
}
