.tc-main-container {
    border: 2px solid #29c5ea;
    border-radius: 8px;
    padding: 0 !important;
    margin: 20px 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  .tc-btn-dark {
    border-radius: 10px;
    background-color: rgba(13, 13, 13, 0.8);
    border: 2px solid #29c5ea;
    padding: 0 30px;
    color: white;
    font-weight: 500;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 16px;
    margin-top: 20px;
    align-self: center;
    padding-top: 2.5px;
    padding-bottom:2.5px;
  }
  
  .tc-btn-dark:hover {
    border-radius: 18px;
    background-color: #29c5ea;
    border: 2px solid #29c5ea;
    /* padding: 5px 20px; */
    color: white;
    /* padding: 5px 40px; */
  }
  
  .tc-form-control {
    border-bottom: 2px solid black;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0px;
    width: 80vw;
    margin: 0;
    /* border: 1px solid saddlebrown; */
    display: flex;
    align-items: center;
    justify-self: center;
  }
  
  .tc-img-fluid {
    height: 95%;
    width: 95%;
    border-bottom-left-radius: 85px;
    border-top-right-radius: 85px;
    object-fit: cover;
  }
  
  .tc-message-text {
    font-size: 40px;
    color: grey;
  }
  
  .tc-message-area {
    height: 25px;
  }
  
  .tc-form-label {
    font-size: 15px;
    color: grey;
    font-family: 'Montserrat', sans-serif;
  }
  
  .tc-social-media-icon {
    color: black;
    font-size: 20px;
    margin: 0 5px;
    /* border: 1px solid red; */
  }
  
  .tc-icon-box {
    /* margin-top: 130px; */
    margin-top: -15px;
  }
  .tc-heading {
    font-size: 25px;
    /* margin-top: 15px; */
  }
  
  .tc-heading strong {
    color: #2f327d;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-align: center !important;
    /* border: 1px solid red; */
    align-self: center;
  }
  
  .tc-contact-title {
    font-family: 'Montserrat', sans-serif;
    color: #29c5ea;
    font-weight: 300;
    font-size: 12px;
    text-decoration: none;
    /* border-bottom: 2px solid #25b3bc; */
    /* border: 1px solid red; */
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .tc-office-title {
    font-family: 'Montserrat', sans-serif;
    color: #29c5ea;
    font-weight: 300;
    font-size: 12px;
    text-decoration: none;
    /* border-bottom: 2px solid #25b3bc; */
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .tc-contact-email {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: black;
    /* border: 1px solid green; */
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .tc-contact-location {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: black;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .tc-contact-box {
      /* border: 1px solid saddlebrown; */
      margin: 0 !important;
    padding: 0 !important;
    
  }
  
  .tc-office-box {
      /* border: 1px solid saddlebrown; */
      margin: 0 !important;
    padding: 0 !important;
  }