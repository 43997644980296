.card-image {
    object-fit: cover;
    height: 200px;
    width: 18rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .card-blog {
    margin: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .blog-main-section {
    display: flex;
    flex-direction: column;
    background-color: white;
    font-family: 'Poppins';
  }
  
  .blog-heading {
    text-align: center;
    margin-top: 2rem;
    line-height: 1.6;
    font-family: 'Poppins';
  }
  
  .blog-sub-heading {
    text-align: center;
    line-height: 1.6;
    padding-bottom: 2rem;
    font-family: 'Poppins', sans-serif;
    width: 60%;
    margin: auto;
    font-weight: 300;
  
  }
  
  .blog-image {
    object-fit: cover;
    height: 500px;
    width: 100%;
  }
  
  .blog-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .blog-back:hover {
    transform: scale(1.5); /* Slightly increase size */
    color: black; /* Change text color */
}

.blog-back button {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.blog-back button:hover {
  transform: scale(5.0);
}
  
  .blog-back-margin {
    margin-top: 40px;
  }
  
  .blog-gap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .blogger-image {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 100%;
    margin-bottom: 10px;
  }
  
  .blogger-image-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .blogger-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blogger-name {
    font-size: 20px;
    font-family: 'Audiowide', sans-serif;
    text-align: center;
    font-weight: 300;
  }
  
  .blog-description {
    line-height: 1.6;
    font-family: 'Poppins', sans-serif;
  }
  
  .topic-heading {
    line-height: 1.6;
    font-weight: 700;
    margin-top: 10px;
  }
  
  .blog-explanation {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  
  }
  
  .topic-subheading {
    line-height: 1.6;
    font-family: 'Poppins';
    font-weight: 500;
  }
  
  
  .topic-image {
    object-fit: cover;
    height: 500px;
    width: 100%;
    margin-left: 5rem;
    margin-right: 5rem;
  }
  
  .topic-images {
    object-fit: contain;
    height: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .topic-iframe {
    object-fit: contain;
    max-height: 90vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .image-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  
  .click {
    cursor: pointer;
  }
  
  .grey {
    color: #29c5ea;
    padding-bottom: 50px;
  }
  
  .link {
    color: dodgerblue;
  }
  
  @media screen and (max-width: 768px) {
  
    h1 {
      font-size: 30px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    h3 {
      font-size: 18px;
    }
  
    h4 {
      font-size: 16px;
    }
  
    h5 {
      font-size: 14px;
    }
  
    h6 {
      font-size: 12px;
    }
  
    p {
      font-size: 12px;
    }
  
    .blog-explanation {
      padding-left: 0rem;
      padding-right: 0rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  
    .blog-image {
      height: 150px;
      width: 100%;
    }
  
    .blog-back {
      margin-top: 10px;
      color:blue
    }

  
    .blog-gap {
      margin-bottom: 0px;
    }
  
    .topic-images {
      height: 200px;
      width: 100%;
    }
  
  }
  
  @media (min-width: 2560px) {
    .blog-main-section {
      padding: 5rem 10rem;
      font-size: 24px;
    }
  
    .blog-heading {
      font-size: 56px;
      margin-top: 4rem;
      line-height: 1.6;
    }
  
    .blog-sub-heading {
      font-size: 36px;
      width: 70%;
      margin: 3rem auto;
      font-weight: 400;
    }
  
    .blog-image {
      object-fit: cover;
      height: 800px;
      width: 100%;
      margin-bottom: 3rem;
    }
  
    .blogger-div {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  
    .blogger-image {
      height: 150px;
      width: 150px;
    }
  
    .blogger-name {
      font-size: 26px;
      font-weight: 400;
    }
  
    .topic-heading {
      font-size: 36px;
      font-weight: 700;
      margin-top: 30px;
    }
  
    .topic-subheading {
      font-size: 28px;
      font-weight: 500;
      margin-top: 20px;
    }
  
    .blog-description {
      font-size: 24px;
      line-height: 1.8;
      margin-bottom: 2.5rem;
    }
  
    .topic-images {
      height: 900px;
      width: 100%;
    }
  
    .topic-iframe {
      max-height: 800px;
      width: 100%;
      margin-bottom: 40px;
    }
  
    .blog-explanation {
      padding: 6rem 12rem;
    }
  
    .blog-back-margin {
      margin-top: 60px;
    }
  
    .blog-back {
      font-size: 26px;
    }
  
    .link {
      font-size: 28px;
    }
  
    .image-box {
      margin-top: 50px;
    }
  }