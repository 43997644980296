/* header css */

.discord-button {
  border-radius: 10px;
  background-color: #3b3b3b;
  border: 2px solid #29c5ea;
  padding: 5px 20px;
  color: white;
  font-weight: 300;
  margin-top:20px;
  
  
}

.discord-button:hover {
  border-radius: 10px;
  background-color: #29c5ea;
  padding: 5px 20px;
  color: white;
  padding: 5px 20px;
  font-weight:300;
}

.navbar {
  height: 110px;
    /* position: fixed; */
  font-family:  Poppins, sans-serif;
  z-index: 1055;
  background: transparent; /* Make it transparent */
  overscroll-behavior: contain;
}

.navbar.colorChange {
  background-color: rgba(255, 255, 255, 0) !important; 
}

.navbar-title {
  font-size: 25px;
  color: white;
  margin-left: 10px !important;
  font-weight: 700;
}


.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050; /* Higher than the modal z-index (1040) */
  /* background-color: rgba(0, 0, 0, 0.5); Optional: to give a semi-transparent background */
  width: 300px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.navbar-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
  z-index: 11; /* Ensure logo stays visible */
  margin-left: 5px;
  margin-top: 30px ;
}

.navbar-icon-box {
  background-color: black;
  border: 2px solid #29c5ea;
}

.navbar-toggler-icon {
  /* border-color: white;
    color: white; */
  /* background-color: black; */
  /* border: 4px solid #25B3BC; */
}

/* Manually Added  */

/* 
@media (max-width: 991.98px) {
    .navbar-toggler {
    }

    .navbar-collapse {
     float: right;
    }

} */

@media (min-width: 768px) {
  .navbar-brand {
    margin-left: 1px;
  }
}

@media only screen and (max-width: 767px) {
  /* .navbar-collapse {
        background-color: rgba(13, 13, 13, 0.80);
    } */

  .nav-bg {
    background-color: #00061c;
  }
  .nav-link {
    padding: 0 !important;
    margin: 5px 0 !important;
  }

  .navbar-nav {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .discord-button {
    margin: 8px 0;
    justify-content: flex-end;
    margin-right:1;
  }

  .nav-alignment {
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important; /* Ensures alignment to the right */
    width: 100%;
  }

  .navbar_two {
    height: 70px;
    margin: 0 !important;
    /* position: fixed; */
    padding: 0 !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 1024px) {
    .navbar-collapse {
        /* display: flex;
        align-items: flex-end;
        justify-content: flex-end; */
    } 

    .nav-alignment {
        display: flex;
        align-items: flex-start;
    }
}
