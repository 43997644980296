.tf-feature-column {
  background-color: #edf8f8;
  padding: 70px 0;
  /* display: flex;
    flex-direction: column; */
}

.tf-feature-first-pic {
  height: 120px;
  width: 120px;
  border-radius: 50% !important;
  border: 8px solid white;
  object-fit: cover;
}

.tf-feature-second-pic {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 8px solid white;
}

.tf-feature-third-pic {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 8px solid white;
  object-fit: cover;
}

.tf-feature-column {
  /* border: 1px solid aqua; */
  display: flex;
  flex-direction: row;
}

.tf-feature-first-column {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}

.tf-feature-second-column {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 10px
}

.tf-first-pic-div {
  position: relative;
  left: 40px;
}

.tf-second-pic-div {
  /* border: 1px solid green; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tf-second-pic-div h1 {
  font-size: 25px;
  text-align: center;
  font-weight: 800;
  /* border: 1px solid greenyellow; */
  margin: 0 0 0 40px !important;
  padding: 20px 0;
  /* border: 1px solid saddlebrown; */
  width: 20vw !important;
}

.tf-live-span {
  font-size: 38px;
  font-weight: 400;
}

.tf-features-div {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 4px solid #29c5ea;
  border-radius: 15px;
  /* padding: 18px 40px; */
  padding: 10px 0 5px 25px;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.tf-features-div h2 {
  font-size: 20px;
  font-weight: 600;
}

.tf-icon-container {
  position: absolute;
  left: -50px;
  display: flex;
  align-items: center;
  width: 70px;
  height: 70px;
  background-color: #29c5ea;
  justify-content: center;
  border-radius: 50%;
}

.tf-feature-icon {
  object-fit: contain;
}
