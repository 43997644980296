.mj-bg-color {
  background-color: #edf8f8;
}

.mj-job-experience-center {
  max-width: 100vw;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  /* align-items: center;
    justify-content: center; */
  /* border: 2px solid red; */
  padding-top: 20px;
}

.mj-job-features {
  margin-top: 30px;
}

.mj-btn-container {
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 25px;
  text-align: center;
  background-color: white;
  font-size: 25px;
  margin: 0px 5px;
}

.mj-button {
  display: inline-block;
  padding: 10px 0;
  background-color: #0d0d0dcc;
  /* You can change this to your preferred color */
  color: #fff;
  text-decoration: none;
  border-radius: 15px;
  border: 4px solid #25b3bc;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 60vw !important;
}

.mj-button:hover {
  background-color: #25b3bc;
  color: white;
}

.mj-job-features {
  /* border: 1px solid greenyellow; */
}

.mj-experience-top {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -40px;
  height: 120px;
}

.mj-experience-top-part {
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 30px;
}

.mj-experience-top-part p {
  text-align: center;
  font-size: 28px;
}

.mj-experience-top-part h5 {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}

.mj-experience-job-heading {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.mj-font-family-button {
  font-family: 'Poppins', sans-serif;
}

.mj-font-family-description {
  font-family: 'Poppins', sans-serif;
}

/* Media Query*/

/* Small devices (landscape phones, 576px and up) */

@media only screen and (max-width: 575px) {
  .mj-custom-font-size {
    font-size: 18px;
  }

  .mj-custom-font-size-button {
    font-size: 18px;
  }

  .mj-jex-buttons {
    width: 100%;
  }
  .mj-top-level-heading {
    font-size: 22px;
  }
}
