.carousel-button-group {
  /* position: absolute;
  top: 50%;
  width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}
.custom-card {
  width: 20rem;
  height: 420px; /* Fixed height to prevent content shift */
  background: #EDF8F8;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden; /* Ensures content does not overflow */
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 15px;
}

.truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  height: 48px; /* Adjusted to fit text properly */
}

.button-container {
  margin-top: auto; /* Pushes button to bottom */
  display: flex;
  justify-content: center;
}


/* .button-side { */
  /* z-index: 2; */
/* } */

/* .button-center { */
  /* position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%); */
/* } */

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 3; /* Limit to 3 lines */
  /* -webkit-box-orient: vertical; */
} 

.description.expanded {
  -webkit-line-clamp: unset; /* Remove line limit when expanded */
  overflow: visible;
}

.read-more-btn {
  border-radius: 10px;
    background-color: rgba(13, 13, 13, 0.8);
    border: 2px solid #29c5ea;
    padding: 0 30px;
    color: white;
    font-weight: 500;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 16px;
    margin-top: 20px;
    align-self: center;
    padding-top: 2.5px;
    padding-bottom:2.5px;
}

.read-more-btn:hover {
  background-color: #5ad4f3;
  border: 2px solid #5ad4f3;
  color: rgb(255, 255, 255);
}

.my-own-custom-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 95vh; */
  padding: 60px 0 70px 0 !important;
}

.truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  height: 40px; /* Adjust height accordingly */
}

.carousel-heading {


  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1vh;
}

.rounded-img {
  border-radius: 16px;
  border: none;
  background-color: #29c5ea;
  transition: transform 0.3s ease-in-out;
  height: 100%; /* Ensure uniform card size */
}
 

.rounded-img:hover {
  transform: scale(1.02); /* Increase size by 5% */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.card-bg-remove {
  background-color: white;

}

/* .carousel-container .react-multiple-carousel__arrow--left { */
  /* left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10; */
/* } */

/* .carousel-container .react-multiple-carousel__arrow--right { */
  /* right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10; */
/* } */

.carousel-container .react-multiple-carousel__arrow--left {
  left: 0px; /* Adjust to move it outside */
}

/* Move right arrow further to the right */
.carousel-container .react-multiple-carousel__arrow--right {
  right: 0px; /* Adjust to move it outside */
}



.carousel-container {
  padding-left: 0px;
  padding-right: 0px;
}

.carousel-item-padding-10-px {
  padding-left: 0px;
  padding-right: 0px;
}

/* .card-img-wrapper {} */

.carousel-top-level-heading {
  font-size: 45px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #2F327D;
  text-align: center;
  margin-bottom: 50px;
}


.card-overlay-button {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  font-size: 12px;
  background-color: white;
  color: black;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
}

.card-overlay-button:hover {
  background-color: white;
  color: black;
}

@media only screen and (max-width: 575px) {

  .main-carousel-heading {
    text-align: center;
    font-size: 35px !important;
    font-weight: 500 !important;
    margin-bottom: 15px;
    color: #2f327d;
  }

  .carousel-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }

  .my-own-custom-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 80vh; */
    padding: 60px 0 70px 0 !important;
  }

  .carousel-top-level-heading {
    font-size: 32px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #2F327D;
    text-align: center;
    margin-bottom: 20px;
  }
}


@media only screen and (min-width: 576px) and (max-width: 1024px) {

  .carousel-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .my-own-custom-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
  }

  .main-carousel-heading {
    margin-bottom: 20px !important;
    font-size:35px;
    color: #00061c;
  }
}

.main-carousel-heading {
  font-size: 45px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #00061c;
  text-align: center;
  margin-bottom: 20px !important;
}

@media only screen and (min-width: 1024px) and (max-width: 1366px){
  .fs-xl-heading {
    font-size: 35px !important;
    font-weight: 600;
  }

  .fs-xl-para {
    font-size: 20px !important;
  }

  .fs-sm-para {
    font-size: 16px !important;
  }
  .whoweare{
    background-color:rgba(41, 197, 234, 0.05);
  }
}

@media only screen and (min-width: 1500px) {
  .fs-xl-heading {
    font-size:40px !important;
  }

  .fs-xl-para {
    font-size: 22px !important;
  }

  .fs-sm-para {
    font-size: 18px !important;
  }

  .whoweare{
    background-color:rgba(41, 197, 234, 0.1);
  }
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
  .custom-card {
    height: 450px; /* Slightly taller for MacBooks */
  }
  
  .truncated-text {
    height: 50px; /* Increase text container height */
    text-overflow: ellipsis;
  }
  
  .card-content {
    padding: 20px; /* More padding to maintain spacing */
  }
  
  .read-more-btn {
    font-size: 14px; /* Reduce button size slightly */
    padding: 5px 20px;
  }
}

/* Specific Fix for MacBook Air & MacBook Pro */
@media screen and (min-width: 1440px) and (max-width: 1680px) {
  .custom-card {
    height: 430px; /* Adjust height for smaller MacBook screens */
  }

  .truncated-text {
    height: 45px;
    text-overflow: ellipsis;
  }

  .read-more-btn {
    font-size: 14px;
    padding: 4px 18px;
  }
}

/* Large Retina Display MacBooks */
@media screen and (min-width: 2560px) {
  .custom-card {
    height: 480px;
  }

  .truncated-text {
    height: 55px;
    text-overflow: ellipsis;
  }

  .read-more-btn {
    font-size: 16px;
    padding: 6px 25px;
  }
}