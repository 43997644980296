.whoweare {
  /* background-color: rgba(41, 197, 234, 0.05); */
  /* border: 1px solid red; */
  padding: 60px 0 70px 0 !important;
}

/* .whoweare-main {
  border: 4px solid salmon;
} */

.question-mark {
  color: #f48c06;
}

.whoweare-section-title h2 {
  font-size: 45px;
  font-weight: 600;
  color: #2f327d;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
}

.whoweare-section-title p {
  font-size: 25px;
  font-family: 'Nunito', sans-serif;
}

.mobile-heading {
  font-size: 45px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #2f327d;
  text-align: center;
}

.experience-job-heading {
  font-size: 45px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #2f327d;
  text-align: center;
  margin-bottom: 20px !important;
}

/* Features Box Style */

.company-features {
  /* border: 1px solid aquamarine; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 40px;
}

.company-feature {
  width: 23vw !important;
  /* border: 1px solid green; */
  /* height: 48vh !important; */
  margin: 0 35px;
  /* height: 100%; */
}

.whoweare .feature-icon-box {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  background: #fff;
  height: 100% !important;
  padding: 20px 15px;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.whoweare .feature-icon-box .feature-title-box h4 {
  font-weight: 600;
  font-size: 22px;
  color: #00061c;
  font-family: 'Poppins', sans-serif;
  height: 50px;
  /* border: 1px solid green; */
  margin-top: 15px;
}

.whoweare .feature-icon-box p {
  line-height: 30px;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  margin: 0 !important;
  padding: 0 !important;
  /* border: 1px solid green; */
  height: 120px;
}

.feature-title-box {
  /* border: 1px solid greenyellow; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.img-div {
}

.whoweare-icon {
  height: 50px;
  width: 50px;
}

.enter-jex-button {
  display: inline-block;
  padding: 5px 0 !important;
  background-color: #0d0d0dcc;
  /* You can change this to your preferred color */
  color: #fff;
  text-decoration: none;
  border-radius: 15px;
  border: 4px solid #25b3bc;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 15vw;
  margin-top: 15px;
  text-decoration: none !important;
}

.enter-jex-button:hover {
  background-color: #25b3bc;
  color: #fff !important;
}

@media only screen and (max-width: 767px) {
  .whoweare-section-title {
    padding: 0 10px;
  }
  .whoweare-section-title h2 {
    font-size: 35px !important;
    font-weight: 600;
    color: #2f327d;
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
  }
  .whoweare-section-title p {
    font-size: 20px !important;
    font-family: 'Nunito', sans-serif;
    /* border: 2px solid saddlebrown; */
    /* text-align: justify !important; */
    text-align: center !important; /* Ensures proper alignment */
    line-height: 1.5 !important; /* Improves spacing */
    word-break: break-word; /* Prevents text from stretching */
  }
  .mobile-heading {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #2f327d;
  }
  .terafac-title {
    font-family: 'Poppins', sans-serif;
  }
  .description-size {
    font-size: 18px;
  }
  .experience-job-heading {
    text-align: center;
    font-size: 32px !important;
    font-weight: 500 !important;
    margin-bottom: 15px;
    color: #2f327d;
  }

  /* Features Box Style */

  .company-features {
    border: none !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* padding-top: 40px; */
    flex-direction: column;
    width: 85vw !important; /* Make feature box more compact */
    margin: 20px auto !important; /* Reduces spacing between boxes */
  }

  .company-feature {
    width: 75vw !important;
    /* border: 1px solid green; */
    /* height: 52vh !important; */
    /* margin: 0 35px; */
    /* margin: 0 !important; */
    /* height: 100%; */
    border: none !important;
    margin: 40px 0 0 0 !important;
  }

  .whoweare .feature-icon-box {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    background: #fff;
    height: 100% !important;
    padding: 30px 15px;
    border: none !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100% !important;
  }

  .whoweare .feature-icon-box .feature-title-box h4 {
    font-weight: 600;
    font-size: 22px;
    color: #2f327d;
    font-family: 'Poppins', sans-serif;
    height: 50px;
    border: none !important;
    margin-top: 10px;
  }

  .whoweare .feature-icon-box p {
    line-height: 30px;
    line-height: 1.4 !important;
    font-size: 22px;
    font-family: 'Nunito', sans-serif;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    height: 150px;
    text-align: justify !important;
  }

  .feature-title-box {
    /* border: 1px solid greenyellow; */
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .whoweare-icon {
    height: 30px;
    width: 30px;
  }

  .enter-jex-button {
    display: inline-block;
    padding: 5px 0 !important;
    background-color: #0d0d0dcc;
    /* You can change this to your preferred color */
    color: #fff;
    text-decoration: none;
    border-radius: 15px;
    border: 4px solid #25b3bc;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 60vw;
    margin-top: 15px;
    text-decoration: none !important;
  }

  .enter-jex-button:hover {
    background-color: #25b3bc;
    color: #fff !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .whoweare-section-title {
    padding: 0 10px;
  }

  .whoweare-section-title h2 {
    font-size: 35px !important;
    font-weight: 600;
    color: #2f327d;
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
  }

  .whoweare-section-title p {
    font-size: 20px !important;
    font-family: 'Nunito', sans-serif;
  }

  /* Features Box Style */

  .company-features {
    border: none;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 40px;
  }

  .company-feature {
    width: 45vw !important;
    /* height: 45vh !important; */
    /* height: 100%; */
    margin: 0 !important;
  }

  .whoweare .feature-icon-box {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    background: #fff;
    height: 100% !important;
    padding: 20px 15px;
    border: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 10px !important;
  }

  .whoweare .feature-icon-box .feature-title-box h4 {
    font-weight: 600;
    font-size: 22px;
    color: #2f327d;
    font-family: 'Poppins', sans-serif;
    height: 80px;
    border: none;
    margin-top: 10px;
  }

  .whoweare .feature-icon-box p {
    line-height: 30px;
    font-size: 22px;
    font-family: 'Nunito', sans-serif;
    margin: 0 !important;
    padding: 0 !important;
    border: none;
    height: 220px;
  }

  .feature-title-box {
    /* border: 1px solid greenyellow; */
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .img-div {
  }

  .whoweare-icon {
    height: 30px;
    width: 30px;
  }

  .enter-jex-button {
    display: inline-block;
    padding: 5px 0 !important;
    background-color: #0d0d0dcc;
    /* You can change this to your preferred color */
    color: #fff;
    text-decoration: none;
    border-radius: 15px;
    border: 4px solid #25b3bc;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 20vw;
    margin-top: 15px;
    text-decoration: none !important;
  }

  .enter-jex-button:hover {
    background-color: #25b3bc;
    color: #fff !important;
  }
}

@media only screen and (max-width: 1024px) {
  .fs-xl-heading {
    font-size: 35px !important;
  }

  .fs-xl-para {
    font-size: 22px !important;
  }
  .whoweare{
    background-color:rgba(41, 197, 234, 0.05);
  }

  .fs-sm-para {
    font-size: 18px !important;
  }
}


@media only screen and (min-width: 1500px) {
  .fs-xl-heading {
    font-size: 35px !important;
  }

  .fs-xl-para {
    font-size: 22px !important;
  }
  .whoweare{
     background-color:rgba(41, 197, 234, 0.07)
  }

  .fs-sm-para {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 767px) {
  .whoweare-section-title p {
    font-size: 18px !important;
    font-family: 'Nunito', sans-serif;
    text-align: center !important; /* Ensures proper alignment */
    line-height: 1.5 !important; /* Improves spacing */
    word-break: break-word; /* Prevents text from stretching */
  }

  .whoweare .feature-icon-box p {
    line-height: 1.4 !important; /* Fixes large gaps between words */
    font-size: 18px !important;
    text-align: center !important;
    word-break: break-word;
  }

  .company-feature {
    width: 85vw !important; /* Make feature box more compact */
    margin: 20px auto !important; /* Reduces spacing between boxes */
    padding: 10px !important;
  }
  
  .whoweare .feature-icon-box {
    padding: 20px 10px !important; /* Reduce inside padding */
  }

  .whoarewe {
    background-color:rgba(41, 197, 234, 0.07)
  }
  
}

@media (max-width: 1728px) {
  .whoweare {
    background-color:rgba(41, 197, 234, 0.07)
  }
}

/* Styles for 14-inch MacBook Pro */
@media (max-width: 1536px) {
  .whoweare {
    background-color:rgba(41, 197, 234, 0.07)
  }
}

/* Styles for 13-inch MacBook Air & Pro */
@media (max-width: 1440px) {
  .whoweare {
    background-color:rgba(41, 197, 234, 0.07)
  }
}

/* General MacBook responsiveness */
@media (max-width: 1280px) {
  .whoweare {
    background-color:rgba(41, 197, 234, 0.07)
  }
}