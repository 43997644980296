.talk-to-us-heading {
    font-size: 2vw;
    font-family: 'Roboto Slab';
    font-weight: 600;
    color: black;
    text-align: center;
}

.talk-to-us-button {
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 5px;
    font-weight: 800;
    font-size: 20px;
    color: black;
    max-height: 100%;
    transition: box-shadow 0.3s ease;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4), 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.container-boxe {
    background-color: #00CCFF;
    border-radius: 15px;
    padding: 30px 30px;
}

.banner-container {
    background-color: rgba(0, 6, 18, 0.9);
    padding-left: 4vw !important;
    padding-right: 4vw !important;
}

/* Media query for tablets */
@media (max-width: 767px) {
    .talk-to-us-heading {
        font-size: 4.8vw;
        font-family: 'Roboto Slab';
        text-align: center;
        width: 100%;
    }

    .talk-to-us-button {
        font-size: 13px;
        padding-left: 2vw !important;
        padding-right: 2vw !important;
        color: black;
        width: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        height: 80%;
    }

    .heading-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .banner-container {
        background-color: rgba(0, 6, 18, 0.9);
    }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
    .talk-to-us-heading {
        width: 100%;
    }

    .heading-container {
        width: 100%;
    }
}

@media (min-width: 2560px) {
    .container-boxe {
        background-color: #00CCFF;
        border-radius: 15px;
        padding: 50px 60px;
    }

    .header-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        margin: 0 !important;
    }

    .talk-to-us-button {
        width: 60%;
        height: 100%;
        border-radius: 10px;
        font-size: 30px;
    }
}