@import url('https://fonts.googleapis.com/css2?family=Qwigley&display=swap');

#hero {
  width: 100vw;
  min-height: 100vh;
  background: url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80') ;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.identify {
  font-family: 'qwigley', cursive !important;
  font-size: 10vw; /* Adjust size */
  font-weight: 400; /* Ensure correct weight */
  letter-spacing: 0px; /* Prevents spacing issues */
  white-space: normal;
  text-transform: none !important;
} 

.identify-text {
  font-size: 50px !important;
}

@media only screen and (max-width: 575px) {
  .identify {
    font-size: 50px;
    font-weight: 400;
  }
  .identify-text {
    font-size: 22px !important;
    /* font-size: 55px !important; */
    line-height: 70px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1024px) {
  /* .identify {
    font-size: 50px !important;
    font-weight: 400;
  } */

  /* .identify-text {
    font-size: 60px !important;
    line-height: 220px I !important;
  } */

  /* .identify-text {
    font-size: 55px !important;
    line-height: 70px !important;
  } */
}

#hero:before {
  content: '';
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  padding-top: 72px;
  position: relative;
  text-align: center;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 70px;
  font-weight: 700;
  line-height: 56px;
  line-height: 1.3;
  color: #fff;
}

#hero h2 {
  color: #eee;
  margin-bottom: 40px;
  font-size: 24px;
}

#hero .play-btn {
  width: 94px;
  height: 94px;
  margin: 0 auto;
  background: radial-gradient(#009961 50%, rgba(0, 153, 97, 0.4) 52%);
  border-radius: 50%;
  display: block;
  overflow: hidden;
  position: relative;
}

#hero .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#hero .play-btn::before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 3s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(0, 153, 97, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

#hero .play-btn:hover::after {
  border-left: 15px solid #009961;
  transform: scale(20);
}

#hero .play-btn:hover::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.feature-box {
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 150%;
  left: 35%;
  right: 35%;
  width: 30vw;
  padding: 30px 30px;
}

/* .identify-text {
  font-family: 'Qwigley';
  font-size: 150px;
  font-weight: 400;
} */

@media only screen and (max-width: 575px) {
  #hero {
    width: 100vw;
    height: 50vh;
    background: url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')
      top center;
    background-size: cover;
    position: relative;
  }
}
