.learning-about .content h3 {
    font-weight: 600;
    font-size: 36px;
    font-family: "Poppins", sans-serif;
}

.learning-about .content p:last-child {
    margin-bottom: 0;
}

.learning-about {
    background-color: rgba(0, 6, 18, 0.9);
    padding-left: 4vw !important;
    padding-right: 4vw !important;
}

.learning-about-description-paragarph {
    font-size: 25px;
    text-align: justify;
    color: white;
}

.learning-about-description-paragraph {
    color: white;
    text-align: justify;
    font-size: 25px;
}

.learning-about-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 50px;
    font-weight: 600;
    color: white;
}

/* .school-lab-box {
    padding: 3vw 0;
} */

/* Small mobile screen */
@media only screen and (max-width: 320px) {
    .learning-about .content h3 {
        font-weight: 600;
        font-size: 36px;
        font-family: "Poppins", sans-serif;
    }

    .learning-about .content p:last-child {
        margin-bottom: 0;
    }

    .learning-about-heading {
        font-family: 'Roboto Slab', serif;
        font-size: 20px;
        font-weight: 600;
        color: white;
    }

    .learning-about {
        background-color: rgba(0, 6, 18, 0.9);
        padding-left: 2vw !important;
        padding-right: 2vw !important;
        margin-top: 30px !important;
    }

    .learning-about-description-paragraph {
        font-family: 'Poppins';
        text-align: justify;
        color: white;
        font-size: 16px;

    }

    .learning-about-image {
        height: 100%;
    }
}

/* Medium mobile screen */
@media only screen and (min-width: 321px) and (max-width: 375px) {
    .learning-about .content h3 {
        font-weight: 600;
        font-size: 36px;
        font-family: "Poppins", sans-serif;
    }

    .learning-about .content p:last-child {
        margin-bottom: 0;
    }
    .learning-about-heading {
        font-family: 'Roboto Slab', serif;
        font-size: 20px;
        font-weight: 600;
        color: white;
    }

    .learning-about {
        background-color: rgba(0, 6, 18, 0.9);
        padding-left: 2vw !important;
        padding-right: 2vw !important;
        margin-top: 30px !important;
    }

    .learning-about-description-paragraph {
        font-family: 'Poppins';
        text-align: justify;
        color: white;
        font-size: 18px;
    }


    .learning-about-image {
        height: 100%;
    }
}

/* Large Mobile */
@media only screen and (min-width: 376px) and (max-width: 767px) {
    .learning-about .content h3 {
        font-weight: 600;
        font-size: 36px;
        font-family: "Poppins", sans-serif;
    }

    .learning-about .content p:last-child {
        margin-bottom: 0;
    }
    .learning-about-heading {
        font-family: 'Roboto Slab', serif;
        font-size: 20px;
        font-weight: 600;
        color: white;
    }

    .learning-about {
        background-color: rgba(0, 6, 18, 0.9);
        padding-left: 2vw !important;
        padding-right: 2vw !important;
        margin-top: 30px !important;
    }

    .learning-about-description-paragraph {
        font-family: 'Poppins';
        text-align: justify;
        color: white;
        font-size: 18px;

    }

    .learning-about-description-paragraph {
        color: white;
        text-align: justify;
    }

    .learning-about-image {
        height: 100%;
    }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .learning-about .content h3 {
        font-weight: 600;
        font-size: 36px;
        font-family: "Poppins", sans-serif;
    }
    .learning-about-heading {
        font-family: 'Roboto Slab', serif;
        font-size: 24px;
        font-weight: 600;
        color: white;
    }

    .learning-about .content p:last-child {
        margin-bottom: 0;
    }

    .learning-about {
        background-color: rgba(0, 6, 18, 0.9);
        padding-left: 2vw !important;
        padding-right: 2vw !important;
        margin-top: 30px !important;
    }
    
    .learning-about-description-paragraph {
        font-family: 'Poppins';
        text-align: justify;
        color: white;
        font-size: 22px;
    }

    .learning-about-image {
        height: 100%;
        width: 100%;
    }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {

    .who-we-are-heading {
        font-family: 'Roboto Slab', serif;
        font-weight: 600;
        color: white;
        margin-bottom: 30px;
    }

    .learning-about-description-paragraph {

        font-family: 'Poppins';
        text-align: justify;
        color: white;
        font-size: 20px;
    }

    .learning-about-image {
        height: 80%;
        width: 100%;
    }

    .learning-about-heading {
        font-family: 'Roboto Slab', serif;
        font-size: 35px;
        font-weight: 600;
        color: white;
    }
}


@media only screen and (min-width: 1440px) {

    .who-we-are-heading {
        font-family: 'Roboto Slab', serif;
        font-weight: 600;
        color: white;
        margin-bottom: 30px;

    }

    .learning-about-description-paragraph {
        font-family: 'Poppins';
        text-align: justify;
        color: white;
        font-size: 20px;
    }

    .learning-about-image {
        height: 100%;
        width: 100%;
    }
}
.industry-heading{
    font-size: 20px;
    color: white;
    font-weight: normal;
}

@media only screen and (min-width: 991px) {
    .learning-about-image {
        width: 100%; 
        height: 100%;
        object-fit: cover !important;
        border-radius: 10px;
    }
}

@media (min-width: 2560px) {
    .learning-about {
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding: 4vw 0; */
    }

    .learning-about-heading {
        font-size: 90px;
    }
    .learning-about-image {
        width: 100%; 
        height: 100%;
        object-fit: cover !important;
        border-radius: 25px;
    }
} 