.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* width: 200px; */
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 6px 2px #29c5ea;
  -webkit-box-shadow: 0px 0px 6px 2px #29c5ea;
  -moz-box-shadow: 0px 0px 6px 2px #29c5ea;
  border: none !important;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 20px;
  border: 2px solid #29c5ea;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 5px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.content {
  padding: 20px;
  font-size: 12px;
}

.popup-terafac-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.popup-heading {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  /* margin: 0 !important; */
  padding: 0 !important;
  margin: 0 !important;
}

.popup-paragraph {
  font-size: 12px;
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Poppins', sans-serif;
  /* border: 1px solid red; */
  margin: 6px 0 10px 0 !important;
}

.start-chating-btn {
  border-radius: 13px;
  background-color: #3b3b3b;
  border: 2px solid #29c5ea;
  padding: 3px 30px;
  color: white;
  font-weight: 400;
  text-decoration: none;
  font-size: 14px;
  margin-top: 6px;
  font-family: Poppins, sans-serif;
}

.start-chating-btn:hover {
  border-radius: 13px;
  background-color: #29c5ea;
  color: white;
  font-weight: 400;
  padding: 3px 30px;
  font-size: 14px;
  margin-top: 6px;
  font-family: Poppins, sans-serif;
}

.popup-main-div {
  display: flex;
  flex-direction: row;
  /* border: 1px solid blueviolet; */
  align-items: center;
  justify-content: center;
}

.popup-content-div {
  /* border: 1px solid greenyellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px
}

@media only screen and (max-width: 767px) {
  .popup {
    position: fixed;
    bottom: 10px;
    right: 8px;
    /* width: 200px; */
    background-color: #ffffff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 36px 5px rgba(37, 179, 188, 0.16);
    -webkit-box-shadow: 0px 0px 36px 5px rgba(37, 179, 188, 0.16);
    -moz-box-shadow: 0px 0px 36px 5px rgba(37, 179, 188, 0.16);
    border: none !important;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 20px;
    border: 2px solid #29c5ea !important;
  }
  
  .close-button {
    position: absolute;
    right: 10px;
    top: 5px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .content {
    padding: 20px;
    font-size: 10px;
  }
  
  .popup-terafac-logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .popup-heading {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    /* margin: 0 !important; */
    padding: 0 !important;
    margin: 0 !important;
  }
  
  .popup-paragraph {
    font-size: 12px;
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Poppins', sans-serif;
    /* border: 1px solid red; */
    margin: 6px 0 10px 0 !important;
  }
  
  .start-chating-btn {
    border-radius: 13px;
    background-color: #3b3b3b;
    border: 4px solid #29c5ea;
    padding: 3px 25px;
    color: white;
    font-weight: 400;
    text-decoration: none;
    font-size: 10px;
    margin-top: 6px;
  }
  
  .start-chating-btn:hover {
    border-radius: 13px;
    background-color: #29c5ea;
    border:#29c5ea;
    color: white;
    font-weight: 400;
    padding: 3px 30px;
  }
  
  .popup-main-div {
    display: flex;
    flex-direction: row;
    /* border: 1px solid blueviolet; */
    align-items: center;
    justify-content: center;
  }
  
  .popup-content-div {
    /* border: 1px solid greenyellow; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px
  }
}