.point-click-heading {
    color: white;
    font-size: 2.8vw;
    font-family: 'Roboto Slab';
    text-align: center;
    /* width: 100%; */
    margin-top: 6vw;;
}

.learning-page {
    margin-top: 14vh;
    background-color: black;
}

.point-click-software {
    color: rgba(0, 180, 254, 0.8);
}

@media only screen and (max-width: 320px) {
    .point-click-heading {
        margin-top: 20px !important;
        /* margin-bottom: 5px !important; */
        color: white;
        font-size: 4.8vw;
        font-family: 'Roboto Slab';
        text-align: center;
    }
    .point-click-software {
        color: rgba(0, 180, 254, 0.8);
    }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
    .point-click-heading {
        margin-top: 20px !important;
        /* margin-bottom: 10px !important; */
        color: white;
        font-size: 4.8vw;
        font-family: 'Roboto Slab';
        text-align: center;
        line-height: 25px;
    }
    .point-click-software {
        color: rgba(0, 180, 254, 0.8);
    }

}

@media only screen and (min-width: 376px) and (max-width: 767px) {
    .point-click-heading {
        margin-top: 20px !important;
        /* margin-bottom: 5px !important; */
        color: white;
        font-size: 3.8vw;
        font-family: 'Roboto Slab';
        text-align: center;
        line-height: 30px;
    }
    .point-click-software {
        color: rgba(0, 180, 254, 0.8);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .point-click-heading {
        margin-top: 20px !important;
        margin-bottom: 5px !important;
        color: white;
        font-size: 3.8vw;
        font-family: 'Roboto Slab';
        text-align: center;
        width: 70%;
    }
    .point-click-software {
        color: rgba(0, 180, 254, 0.8);
    }
}

.learning-card-div {
    /* margin-top: 6vw; */
}  

.college-page {
    margin-top: 12vh;
}

@media (min-width: 2560px) {
    .point-click-heading {
        font-size: 68px !important;
    }

    .industry-description {
        font-size: 66px;
    }
} 

.talk-to-us-home {
    margin-top: 5.5vw !important;
}

@media (max-width: 768px) {
    .talk-to-us-home {
        margin-top: 20px !important
    }
} 

.talk-to-us-manufacturing {
    margin-top: 6vw !important;
}

@media (max-width: 768px) {
    .talk-to-us-manufacturing {
        margin-top: 30px !important
    }
}

.contact-platform {
    margin-top: 12vh;
}


