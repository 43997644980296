.gallery {
    margin: 0 4vw;
}

.gallery-item {
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-image {
    width: 100%;
    height: auto;
    object-fit: cover; 
}


/* For mobile devices (up to 575px) */
@media (max-width: 575px) {
    
    .gallery-item {
    }

    .gallery-image {
        border-radius: 10px; 
    }
}

/* For tablets (576px to 991px) */
@media (min-width: 576px) and (max-width: 991px) {
    .gallery-item {
    }

    .gallery-image {
        border-radius: 12px;
    }
}

/* For desktop screens (992px and above) */
@media (min-width: 992px) {

    .gallery-item {
        margin-top: 0.75vw; 
    } 

    .gallery-image {
        border-radius: 15px;
    }
}

/* For 4K screens (3840px and above) */
@media (min-width: 3840px) {

    .gallery-item {
        margin-top: 0.5vw; 
    }

    .gallery-image {
        border-radius: 20px;
    }
}
