.contact {
    margin-top: 6vw;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-bottom: 2vh;
}

@media (max-width: 768px) {
    .contact {
        margin-top: 30px;
        margin-left: 2vw;
        margin-right: 2vw;
    }
}

.contact .info-item {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
    padding: 20px 0 30px 0;
}

.contact .info-item i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    font-size: 24px;
    line-height: 0;
    color: var(--color-primary);
    border-radius: 50%;
    border: 2px solid #00CCFF;
}

.contact .info-item h3 {
    font-size: 20px;
    color: #6c757d;
    font-weight: 700;
    margin: 10px 0;
}

.contact .info-item p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.contact .php-email-form {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
    padding: 30px;
}

.contact .php-email-form .form-group {
    padding-bottom: 20px;
    border-radius: "50%" ;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #df1529;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br+br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #059652;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #059652;
    border-top-color: #fff;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: var(--color-primary);
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {

    background-color: rgba(13, 13, 13, 0.8);
    border: 2px solid #29c5ea;
    padding: 10px 40px;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.contact .php-email-form button[type=submit]:hover {
    background-color: rgba(0, 180, 254, 0.8);
    /* border: 2px solid rgba(0, 180, 254, 0.8); */
    padding: 10px 40px;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.contact .php-email-form textarea {
    height: auto;
    padding: 10px 12px;
    min-height: 130px;
    resize: vertical;
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.contact-card{
    border: 2px solid #29c5ea;
    background-color: white;
    border-radius: 10px;
}

@media (min-width: 2560px) {
    .contact {
        /* margin-top: 2vw; */
        padding-left: 8vw;
        padding-right: 8vw;
    }

    .contact .info-item {
        padding: 90px 0 100px 0;  
    }

    .contact .info-item i {
        width: 80px;     
        height: 80px;
        font-size: 36px;
    }

    .contact .info-item h3 {
        font-size: 28px; 
    }

    .contact .info-item p {
        font-size: 18px;
    }

    .php-email-form {
        padding: 60px; 
        max-width: 1000px; 
        margin: auto; 
    }

    .php-email-form .form-group input,
    .php-email-form .form-group textarea {
        font-size: 22px; 
        padding: 20px;
        height: 60px; 
        line-height: 1.5; 
    }

    .php-email-form textarea {
        height: 220px;
    }

    .php-email-form input::placeholder,
    .php-email-form textarea::placeholder {
        font-size: 20px; 
        opacity: 0.7;
    }

    .contact .php-email-form button[type=submit] {
        background-color: rgba(13, 13, 13, 0.8);
        border: 6px solid rgba(0, 180, 254, 0.8);
        padding: 15px 40px;
        border-radius: 15px;
        font-size: 30px;
        cursor: pointer;
    }

    .contact .php-email-form button[type=submit]:hover {
        background-color: rgba(0, 180, 254, 0.8);
        border: 6px solid rgba(13, 13, 13, 0.8);
        padding: 15px 40px;
        border-radius: 15px;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }

    .php-email-form .form-group {
        padding-bottom: 30px;
    }

    .php-email-form input[type="text"],
    .php-email-form input[type="email"],
    .php-email-form textarea {
        width: 100%; 
    }

    .col-lg-6.bg-tranparent {
        padding-left: 2vw;
        padding-right: 2vw;
    }

    iframe {
        height: 600px !important; 
    }
} 

/* Styling specifically for mobile screens */
@media (max-width: 768px) {
    .php-email-form .form-group {
        padding-bottom: 20px;
        margin-top: 0;  
    }

    #name {
        margin-top: 25px !important;
    }

    /* Remove any margin from the "Your Email" input field */
    .php-email-form input, .php-email-form textarea {
        margin-top: 0 !important; /* Ensure no extra margin at the top */
        padding: 15px;  /* Adjust padding for smaller screens */
        font-size: 16px; /* Slightly smaller font size for better mobile display */
    }

    .php-email-form input::placeholder,
    .php-email-form textarea::placeholder {
        font-size: 14px;  /* Placeholder font size for mobile */
    }

    /* General mobile view form styling */
    .php-email-form {
        padding: 20px;  /* Reduce padding for mobile screens */
    }

    /* Adjust the submit button for mobile */
    /* .php-email-form button[type=submit] {
        padding: 12px 30px;  
        font-size: 16px; 
    } */

    .php-email-form button[type=submit] {
        background-color: rgba(13, 13, 13, 0.8);
        border: 4px solid rgba(0, 180, 254, 0.8);
        padding: 10px 35px;
        border-radius: 10px;
        color: white;
        font-size: 0.9rem;
        cursor: pointer;
    }

    .php-email-form button[type=submit]:hover {
        background-color: rgba(0, 180, 254, 0.8);
        border: 4px solid rgba(13, 13, 13, 0.8);
        padding: 10px 40px;
        border-radius: 10px;
        color: white;
        font-size: 1rem;
        cursor: pointer;
    }
}

