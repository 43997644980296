/* Base Styles */
.about .content h3 {
    font-weight: 600;
    font-size: 36px;
    font-family: "Poppins", sans-serif;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about {
    background-color: rgba(0, 6, 18, 0.9);
    padding-left: 4vw;
    padding-right: 4vw;
    margin-top: 6vw;
}

.who-we-are-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 2.8vw;
    font-weight: 600;
    color: white;
}

.industry-description {
    color: white;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 1.6vw;
    line-height: 1.5;
}

.industry-description span {
    font-weight: 900;
}

.about-description-paragraph {
    color: white;
    text-align: justify;
    font-size: clamp(1.25rem, 2.2vw, 2rem); 
}

/* Responsive Media Queries */
@media only screen and (max-width: 768px) {
    .who-we-are-heading {
        font-size: 4.8vw;
        text-align: center;
        margin-bottom: 10px;
    }
}

/* Small mobile screen */
@media only screen and (max-width: 320px) {
    .about {
        padding-left: 2vw;
        padding-right: 2vw;
        margin-top: 5px !important;
    }
    
    /* .who-we-are-heading {
        font-size: 5vw;
        margin-bottom: 10px;
        text-align: center;
    } */
    
    .industry-description {
        font-size: 2.8vw; 
        margin-top: 18px;
    }

    .industry-description span {
        font-weight: 900;
    }
    
    .about-description-paragraph {
        font-size: 16px;
    }
}

/* Medium mobile screen */
@media only screen and (min-width: 321px) and (max-width: 375px) {
    .about {
        padding-left: 2vw;
        padding-right: 2vw;
        margin-top: 5px !important;
    }

    /* .who-we-are-heading {
        font-size: 5.8vw;
        text-align: center;
        margin-bottom: 13px;
    } */

    .industry-description {
        font-size: 3.8vw; 
        margin-top: 18px;
    }
    
    .industry-description span {
        font-weight: 900;
    }

    .about-description-paragraph {
        font-size: 18px;
    }
}

/* Large Mobile */
@media only screen and (min-width: 376px) and (max-width: 767px) {
    /* .who-we-are-heading {
        font-size: 5.7vw;
        text-align: center;
        margin-bottom: 15px;
    } */
    
    .industry-description {
        font-size: 3.8vw; 
        margin-top: 18px;
    }

    .industry-description span {
        font-weight: 900;
    }
    
    .about-description-paragraph {
        font-size: 18px;
    }

    .about {
        margin-top: 5px !important;
    }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .who-we-are-heading {
        font-size: 3.8vw;
        margin-bottom: 30px;
    }

    .industry-description {
        font-size: 2.5vw; 
    }


    .industry-description span {
        font-weight: 800;
    }

    .about-description-paragraph {
        font-size: 22px;
    }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1919px) {
    .who-we-are-heading {
        margin-bottom: 30px;
    }
    
    .industry-description {
        font-size: 1.8vw; 
        margin-bottom: 30px;
    }

    .about-description-paragraph {
        font-size: 20px;
    }
}

@media only screen and (min-width: 1920px) and (max-width: 2660px) {
    .industry-description {
        font-size: 1.6vw !important; 
        margin-bottom: 30px;
    }
}

@media (min-width: 2560px) {
    .who-we-are-heading {
        font-size: 68px !important;
    }
}


