.df-terafac-live {
  font-family: Poppins, sans-serif;
}

.df-feature-column {
  background-color: #ffffff;
  padding: 70px 0;
  /* display: flex;
  flex-direction: column; */
}

@media only screen and (max-width: 600px) {
  .df-feature-column {
    display: flex;
    flex-direction: column !important;
  }
}

  .df-features-div {
    padding: 20px 0 15px 10px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .df-features-div h2 {
    font-size: 15px !important;
    font-weight: 600;
  }
  
  .df-terafac-container {
  width: 100%;
  text-align: center;
  margin-bottom: 10px; /* Adjust spacing below the heading */
}

.did_you_know {
  background-color: #edf8f8;
  font-family: "Audiowide", sans-serif;
  letter-spacing: 1.2px;

  .df-icon-container {
    width: 30px !important;
    height: 35px !important;
    position: absolute;
    left: -25px !important;
    display: flex;
    align-items: center;
    background-color: #29c5ea;
    justify-content: center;
    border-radius: 50%;
  }

  .df-feature-first-column img {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

  .df-feature-first-column img:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .df-features-div {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
  }
  
  .df-features-div:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
  }
  
  .df-icon-container {
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  .df-feature-second-column {
    padding-left: 50px !important;
  }

  .df-feature-first-pic {
    height: 100px !important;
    width: 80px!important;
    border-radius: 50% !important;
    border: 8px solid white !important;
    object-fit: cover;
  }

  .df-feature-second-pic {
    height: 100px !important;
    width: 100px !important;
    border-radius: 50%;
    object-fit: cover;
    border: 8px solid white !important;
  }

  .df-feature-third-pic {
    height: 120px !important;
    width: 120px !important;
    border-radius: 50%;
    border: 8px solid white !important;
    object-fit: cover;
  }

  .df-first-pic-div {
    position: relative;
    left: 20px !important;
  }

  .df-second-pic-div {
    /* border: 1px solid green !important; */
    margin-left: 4px;
  }

  .df-second-pic-div h1 {
    font-size: 28px !important;
    text-align: right !important;
    font-weight: 800;
    margin: 0 0 0 30px !important;
    padding: 4px 0;
  }

  .df-live-span {
    font-size: 28px !important;
    font-weight: 400;
  }

  .df-feature-first-column {
    /* border: 1px solid orange !important; */
  }  

  .df-first-pic-div {
    /* border: 1px solid red !important; */
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .df-feature-column {
    display: flex;
    flex-direction: column !important;
  }

  .df-features-div {
    padding: 8px 0 2px 10px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .df-features-div h2 {
    font-size: 14px !important;
    font-weight: 600;
  }

  .df-icon-container {
    width: 35px !important;
    height: 35px !important;
    position: absolute;
    left: -25px !important;
    display: flex;
    align-items: center;
    background-color: #25b3bc;
    justify-content: center;
    border-radius: 50%;
  }

  .df-feature-second-column {
    padding-left: 50px !important;
  }

  .df-feature-first-column {
    /* border: 1px solid orange !important; */
  }

  .df-feature-first-pic {
    height: 235px;
    width: 235px;
    border-radius: 50% !important;
    border: 6px solid white;
    object-fit: cover;
  }

  .df-feature-second-pic {
    height: 10px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 6px solid white;
  }

  .df-feature-third-pic {
    height: 280px;
    width: 280px;
    border-radius: 50%;
    border: 6px solid white;
    object-fit: cover;
  }

  .df-first-pic-div {
    position: relative;
    left: 42px !important;
  }

  .df-second-pic-div {
    /* border: 1px solid green !important; */
    margin-left: 4px;
  }

  .df-second-pic-div h1 {
    font-size: 28px !important;
    text-align: right !important;
    font-weight: 800;
    margin: 0 0 0 30px !important;
    padding: 4px 0;
  }

  .df-live-span {
    font-size: 28px !important;
    font-weight: 400;
  }

  .df-first-pic-div {
    /* border: 1px solid red !important; */
  }
}

.df-feature-first-pic {
  height: 180px;
  width: 180px;
  border-radius: 50% !important;
  border: 6px solid white;
  object-fit: cover;
}

.df-feature-second-pic {
  height: 190px;
  width: 190px;
  border-radius: 50%;
  object-fit: cover;
  border: 6px solid white;
}

.df-feature-third-pic {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 6px solid white;
  object-fit: cover;
}

.df-feature-column {
  /* border: 1px solid aqua; */
  display: flex;
  flex-direction: row;
}

.df-feature-first-column {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.df-feature-second-column {
  /* border: 1px solid red; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 70px;
}

.df-first-pic-div {
  position: relative;
  left: 25px;
}

.df-second-pic-div {
  /* border: 1px solid green; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.df-second-pic-div h1 {
  font-size: 38px;
  text-align: center;
  font-weight: 800;
  /* border: 1px solid greenyellow; */
  margin: 0 0 0 30px !important;
  padding: 4px 0;
}

.df-live-span {
  font-size: 38px;
  font-weight: 400;
}

.df-features-div {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 20px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 2px solid #29c5ea;
  border-radius: 15px;
  /* padding: 18px 40px; */
  /* padding: 15px 0 15px 40px; */
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.df-features-div h2 {
  margin: 0;
  padding-left: 30px;
  font-size: 26px;
  font-weight: 600;
}

.df-icon-container {
  position: absolute;
  left: -40px;
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #29c5ea;
  justify-content: center;
  border-radius: 50%;
}

.df-feature-icon {
  /* object-fit: contain; */
  width: 20px; /* Reduced size */
  height: 20px; /* Reduced size */
  display: inline-block !important;
  visibility: visible !important;
}


@media only screen and (min-width: 1024px) {
  .fs-xl-heading {
    font-size: 35px !important;
    font-family: Audiowide,sans-serif;
  }

  .fs-xl-para {
    font-size: 40px !important;
  }
}
