.feature-column {
  background-color: #edf8f8;
  padding: 70px 0;
  /* display: flex;
  flex-direction: column; */
}

.text{
  text-align: center;
  font-size: 32px;
  font-weight: 800;
  color: black;
  font-family: 'Poppins', sans-serif;
}

.live-span {
  font-size: 32px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.feature-first-pic {
  height: 235px;
  width: 235px;
  border-radius: 50% !important;
  border: 6px solid white;
  object-fit: cover;
}

.feature-second-pic {
  height: 235px;
  width: 235px;
  border-radius: 50% !important;
  border: 6px solid white;
  object-fit: cover;
}

.feature-third-pic {
  height: 235px;
  width: 235px;
  border-radius: 50% !important;
  border: 6px solid white;
  object-fit: cover;
}

.features-div h2 {
  font-size: 12px !important;
  font-weight: 600;
  width: 94%
}

.features-div {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 2px solid #29c5ea;
  border-radius: 15px;
  /* padding: 18px 40px; */
  padding: 15px 0 15px 40px;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.feature-column {
  display: flex;
  flex-direction: row;
  background-color: #edf8f8;
  padding: 70px 0;
  /* display: flex;
  flex-direction: column; */
}

@media only screen and (max-width: 575px) {
  .feature-column {
    display: flex;
    flex-direction: column !important;
  }

  .features-div {
    padding: 8px 0 2px 10px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .features-div h2 {
    font-size: 15px !important;
    font-weight: 600;
  }

  .icon-container {
    width: 35px !important;
    height: 35px !important;
    position: absolute;
    left: -25px !important;
    display: flex;
    align-items: center;
    background-color: #29c5ea;
    justify-content: center;
    border-radius: 50%;
  }

  .feature-second-column {
    padding-left: 50px !important;
  }

  .feature-first-pic {
    height: 100px !important;
    width: 100px !important;
    border-radius: 50% !important;
    border: 8px solid white !important;
    object-fit: cover;
  }

  .feature-second-pic {
    height: 100px !important;
    width: 100px !important;
    border-radius: 50% !important;
    border: 8px solid white !important;
    object-fit: cover;
  }

  .feature-third-pic {
    height: 100px !important;
    width: 100px !important;
    border-radius: 50% !important;
    border: 8px solid white !important;
    object-fit: cover;
  }

  .first-pic-div {
    position: relative;
    left: 20px !important;
  }

  .second-pic-div {
    /* border: 1px solid green !important; */
    margin-left: 4px;
  }

  .second-pic-div h1 {
    font-size: 28px !important;
    text-align: right !important;
    font-weight: 800;
    margin: 0 0 0 30px !important;
    padding: 4px 0;
  }

  .feature-first-column {
    /* border: 1px solid orange !important; */
  }  

  .first-pic-div {
    /* border: 1px solid red !important; */
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .feature-column {
    display: flex;
    flex-direction: column !important;
  }

  .features-div {
    padding: 8px 0 2px 10px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .features-div h2 {
    font-size: 15px !important;
    font-weight: 600;
  }

  .icon-container {
    width: 35px !important;
    height: 35px !important;
    position: absolute;
    left: -25px !important;
    display: flex;
    align-items: center;
    background-color: #29c5ea;
    justify-content: center;
    border-radius: 50%;
  }

  .feature-second-column {
    padding-left: 50px !important;
  }

  .feature-first-column {
    /* border: 1px solid orange !important; */
  }

  .feature-first-pic {
    height: 100px !important;
    width: 100px !important;
    border-radius: 50% !important;
    border: 8px solid white !important;
    object-fit: cover;
  }

  .feature-second-pic {
    height: 100px !important;
    width: 100px !important;
    border-radius: 50% !important;
    border: 8px solid white !important;
    object-fit: cover;
  }

  .feature-third-pic {
    height: 100px !important;
    width: 100px !important;
    border-radius: 50% !important;
    border: 8px solid white !important;
    object-fit: cover;
  }

  .first-pic-div {
    position: relative;
    left: 42px !important;
  }

  .second-pic-div {
    /* border: 1px solid green !important; */
    margin-left: 4px;
  }

  .second-pic-div h1 {
    font-size: 28px !important;
    text-align: right !important;
    font-weight: 800;
    margin: 0 0 0 30px !important;
    padding: 4px 0;
  }

  .first-pic-div {
    /* border: 1px solid red !important; */
  }
}

.feature-first-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.feature-second-column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 70px;
}

.first-pic-div {
  position: relative;
  left: 40px;
}

.second-pic-div {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin : 20px 0px;
}

.second-pic-div h1 {
  font-size: 38px;
  text-align: center;
  font-weight: 800;
  /* border: 1px solid greenyellow; */
  margin: 0 0 0 30px !important;
  padding: 4px 0;
}

.icon-container {
  position: absolute;
  left: -50px;
  display: flex;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: #29c5ea;
  justify-content: center;
  border-radius: 50%;
}

.feature-icon {
  object-fit: contain;
  height: 100px;
  width: 100%;
}
