.contact-form-container {
  max-width: 400px;
  margin: 0 auto;
  background-color: #29c5ea;
}
.main-container {
  border: 2px solid #29c5ea;
  border-radius: 8px;
  /* padding: 2px 0;; */
  background-color: rgba(41, 197, 234, 0.05);
} 

.btn-dark {
  border-radius: 10px;
  background-color: #3b3b3b;
  border: 2px solid #29c5ea;
  /* padding: 0 70px; */
  color: white;
  font-weight: 100;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 26px;
  margin-top: 20px;
  width: 96%;
  outline: none;  /* Prevents default outline */
  box-shadow: none; /* Removes any box shadow */
}

.btn-dark:focus, 
.btn-dark:active {
  outline: none !important;
  box-shadow: none !important;
  background-color: #3b3b3b;
  border-color: #29c5ea !important; /* Ensures no unwanted color */
}

.btn-dark:hover {
  border-radius: 10px;
  background-color: #29c5ea;
  border: 2px solid #29c5ea;
  /* padding: 5px 20px; */
  color: rgb(255, 255, 255);
  /* padding: 5px 40px; */
}


.form-control {
  /* border-bottom: 2px solid black; */
  border-color: #29c5ea;
  width: 95%;
  margin: 0;
  border-radius: 10px !important;
  /* padding-top: 30px; */
  /* padding-bottom: 30px; */
  /* height : 10% */
}

.form-control:focus,
.message-area:focus {
  border-color: #29c5ea !important; 
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
  border-radius: 10px

}

.img-fluid {
  height: 95%;
  width: 95%;
  border-bottom-left-radius: 85px;
  border-top-right-radius: 85px;
  object-fit: cover;
}
.message-text {
  font-size: 40px;
  color: #29c5ea;
}
.message-area {
  height: 25px;
}
.form-label {
  font-size: 14px;
  color: #00061C;
  padding: 0 !important;
  margin: 0 !important;
  font-family: 'Montserrat', sans-serif;
}

.social-media-icon {
  color: black;
  font-size: 35px;
  margin-right: 40px;
  /* border: 1px solid red; */
}

.icon-box {
  /* margin-top: 130px; */
}
.heading {
  font-size: 45px;
  margin-top: 15px;
}

.heading strong {
  color: #00061C;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

@media (max-width: 839px) {
  .img-fluid {
    height: 100%;
    width: 100%;
    margin-left: 0px;
  }
  .icon-box {
    margin-top: 13px;
  }
  .main-container {
    border: none;
    margin: 5px;
  }
  .text-box {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.contact-title {
  font-family: 'Montserrat', sans-serif;
  color: #29c5ea;
}

.office-title {
  font-family: 'Montserrat', sans-serif;
  color: #29c5ea;
}

.contact-email {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
}

.contact-location {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
}

.contactus-box {
  /* background-color: antiquewhite; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px 0;
}

.contact-box {
  /* border: 1px solid darkblue; */
  margin-top: 50px
}


@media only screen and (min-width: 1024px) {
  .fs-xl-heading {
    font-size: 35px !important;
  }

  .fs-xl-para {
    font-size: 20px !important;
  }

  .fs-sm-para {
    font-size: 16px !important;
  }
}

