.email-popup {
  position: fixed;
  top: 50%; /* center vertically */
  left: 50%; /* center horizontally */
  transform: translate(-50%, -50%); /* adjust for the element's own size */
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 2px #29c5ea;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #29c5ea;
  z-index: 1000; /* high index to ensure it's on top of other elements */
  padding: 20px 25px;
}

.email-close-button {
  position: absolute;
  right: 0px;
  top: 0px;
  background: none;
  border: #29c5ea !important;
  font-size: 20px;
  cursor: pointer;
}

.error-message {
  color: red; /* Red color for the error message */
  font-size: 14px; /* Slightly smaller font size */
  margin-top: 10px; /* Adds space between the input field and the error message */
  padding-left: 5px; /* Adds some padding from the left */
}

.email-content {
  font-size: 12px;
}

.email-popup-heading {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  padding: 0 !important;
  margin: 0 !important;
}

.email-popup-paragraph {
  font-size: 12px;
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Poppins', sans-serif;
  /* border: 1px solid red; */
  margin: 6px 0 10px 0 !important;
}

.redirect-btn {
  border-radius: 13px;
  background-color: #3b3b3b;
  border: 4px solid #29c5ea;
  color: white;
  font-weight: 400;
  text-decoration: none;
  font-size: 14px;
  margin-top: 15px;
  padding: 3px 30px;
}

.redirect-btn:hover {
  border-radius: 13px;
  background-color: #29c5ea;
  color: white;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 400;
  padding: 3px 30px;
  text-decoration: none;
}

.email-popup-content-div {
  /* border: 1px solid greenyellow !important; */
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  /* padding: 15px */
}

.email-first-name-label {
  font-size: 13px;
  font-weight: 400;
  color: gray;
}

.email-phone-input {
  padding: 6px 15px;
  border: 1px solid rgb(209, 203, 203);;
  border-radius: 5px;
  font-size: 13px;
  width: 20vw;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 767px) {
  .email-popup {
    position: fixed;
    top: 50%; /* center vertically */
    left: 50%; /* center horizontally */
    transform: translate(-50%, -50%); /* adjust for the element's own size */
    background-color: #ffffff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 36px 5px rgba(37, 179, 188, 0.16);
    -webkit-box-shadow: 0px 0px 36px 5px rgba(37, 179, 188, 0.16);
    -moz-box-shadow: 0px 0px 36px 5px rgba(37, 179, 188, 0.16);
    border: none !important;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px solid #29c5ea !important;
    z-index: 1000;
  }
  
  .email-close-button {
    position: absolute;
    right: 0px;
    top: 0px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .email-content {
    font-size: 10px;
  }
  
  .email-popup-heading {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 0 !important;
    margin: 0 !important;
  }
  
  .email-popup-paragraph {
    font-size: 12px;
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Poppins', sans-serif;
    /* border: 1px solid red; */
    margin: 6px 0 10px 0 !important;
  }
  
  .redirect-btn {
    border-radius: 13px;
    background-color: rgba(13, 13, 13, 0.8);
    border: 4px solid #3da1aa;
    color: white;
    font-weight: 400;
    text-decoration: none;
    font-size: 14px;
    margin-top: 15px !important;
    align-self: center;
    padding: 3px 30px;
  }
  
  .redirect-btn:hover {
    border-radius: 13px;
    background-color: #3da1aa;
    color: white;
    font-weight: 400;
    padding: 3px 30px;
  }
  
  .email-popup-content-div {
    /* border: 1px solid greenyellow !important; */
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    width: 100%;
  }

  .email-phone-input {
    padding: 6px 15px;
    border: 1px solid rgb(209, 203, 203);;
    border-radius: 5px;
    font-size: 13px;
    width: 70vw;
    font-family: 'Montserrat', sans-serif;
  }
}