/* General styles */
.main-section {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 6, 18, 0.9);
    padding-left: 4vw !important;
    padding-right: 4vw !important;
    margin-top: 6vw !important;
}

.content-container {
    width: 100%;
    padding: 30px 40px;
    border-radius: 15px;
    background: #FFF;
}

.why-choose-heading {
    font-family: 'Roboto Slab', serif;
    margin-bottom: 0.5em;
    font-size: 2.8vw;
    font-weight: 700;
}

.reasons-ul {
    list-style-position: inside;
    font-family: 'Poppins', sans-serif;
    padding-left: 0;
}

.reasons-ul li {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 1.8vw;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .content-container {
        padding: 20px
    }
}

@media (max-width: 767px) {
    .content-container {
        width: 95%;
    }

    .why-choose-heading {
        font-family: 'Roboto Slab';
        margin-bottom: 1em;
        font-size: 4.8vw;
        font-weight: 700;
    }

    .reasons-ul {
        list-style-position: inside;
        font-family: 'Poppins', sans-serif;
        padding-left: 0;
    }

    .reasons-ul li {
        font-size: 3.5vw;
    }

    .main-section {
        margin-top: 30px !important;
        padding-left: 2vw !important;
        padding-right: 2vw !important;
    }
}

@media (max-width: 480px) {
    .content-container {
        border-radius: 10px;
    }
}

@media (min-width: 2560px) {
    .why-choose-heading {
        margin-bottom: 0.5em;
        font-size: 68px !important;
    }
    
    .reasons-ul li {
        margin-bottom: 15px;
        font-size: 1.5vw;
    }

    .content-container {
        width: 100%;
        padding: 60px 80px;
        border-radius: 15px;
        background: #FFF;
    }
}
