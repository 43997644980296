.manufacturing-hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.manufacturing-hero-division {
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;
}

.manufacturing-hero-heading {
  font-family: 'Audiowide', sans-serif;
  color: white;
  text-align: center;
  font-weight: 500;
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .hero-dynamic-height {
    height: 60vh;
  }
}

@media (max-width: 600px) {
  .hero-dynamic-height {
    height: 70vh;
  }
}

@media (max-width: 480px) {
  .hero-dynamic-height {
    height: 80vh;
  }
}

.manufacturing-hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.manufacturing-hero-text-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  /* background-color: rgba(0, 6, 28, 0.4); */
  /* Semi-transparent black background */
}

/* styles.css */
@media only screen and (max-width: 320px) {
  .manufacturing-hero-text-overlay{
    background-color: rgba(0, 0, 0, 0.4);
    
  }
}

.robot-platform {
  padding-left: 4vw !important;
  padding-right: 4vw !important;
  margin-top: 6vw !important;
}

@media only screen and (max-width: 768px) {
  .robot-platform {
    padding-left: 4vw !important;
    padding-right: 4vw !important;
    margin-top: 30px !important;
  }
}

.mini-factory-div {
  margin-top: 6vw !important;
  padding-left: 4vw;
  padding-right: 4vw;
}

@media only screen and (max-width: 768px) {
  .mini-factory-div {
    padding-left: 4vw !important;
    padding-right: 4vw !important;
    margin-top: 0 !important;
  }
}

